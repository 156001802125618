import React, { createContext, useState, useEffect } from "react";

const UserContext = createContext();
const baseUrl = process.env.Base_URL;

export const UserProvider = ({ children }) => {


    const [user, setUser] = useState(false);
    const [registeredClasses, setRegisteredClasses] = useState([]);
    const [isClassRegistered, setIsClassRegistered] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    let token;
    let refreshToken;
    if (typeof window !== 'undefined') {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refreshToken');
    }

    async function getUserData() {
        setIsLoading(true);

        if (!token) {
            setUser(false);
            setIsLoading(false);
            return false;
        }

        var getUserHeaders = new Headers();
        getUserHeaders.append("Content-Type", "application/json");
        getUserHeaders.append("Authorization", `Bearer ${token}`);

        var raw = "";

        var getUserRequestOptions = {
            method: 'GET',
            headers: getUserHeaders,
        };





        const userData = await (await fetch(`${baseUrl}/me`, getUserRequestOptions)).json();

        console.log('ashhh user data', userData)
        if (userData.id) {
            setUser(userData);
        } else {
            getRefreshToken(getUserData);
        }
        setIsLoading(false);
    }

    async function getUserClassesData() {
        console.log('hassan')
        setIsLoading(true);
        var getClassesHeaders = new Headers();
        getClassesHeaders.append("Content-Type", "application/json");
        getClassesHeaders.append("Authorization", "Bearer " + token);

        var raw = "";

        var getUserClasses = {
            method: 'GET',
            headers: getClassesHeaders,
        };

        let userClasses;
        if (!token) {
            return false;
        }
        // try {

        const res = await fetch(`${baseUrl}/get_my_classes`, getUserClasses);
        if (res.status === 200) {
            userClasses = await res.json();
            setRegisteredClasses(userClasses);
            console.log('ashh arr', userClasses)
        } else if (res.status === 401 && res.statusText === 'Invalid token') {
            getRefreshToken(getUserClassesData)
        }
        // } catch (error) {
        //     console.error(error);
        // }

        setIsLoading(false);
    }

    function checkRegisteration(classId) {
        for (let it = 0; it < registeredClasses.length; it++) {
            if (registeredClasses[it].class_id == classId) {
                setIsClassRegistered(true);
                return false
            } else {
                setIsClassRegistered(false);
                return false
            }
        }
    }

    //get user data
    useEffect(() => {
        getUserData();
        getUserClassesData();
    }, []);

    async function getRefreshToken(cb) {
        setIsLoading(true);
        //fetch for new token / refresh token

        if (typeof window !== 'undefined') {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + token);
            var raw = JSON.stringify({
                "AccessToken": token,
                "RefreshToken": refreshToken
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            let newToken = await (await fetch(`${baseUrl}/token/refresh`, requestOptions)).json();

            console.log(newToken);
            if (newToken['Access Token']) {
                token = newToken['Access Token'];
                localStorage.setItem('token', token);
                refreshToken = newToken['Refresh Token'];
                localStorage.setItem('refreshToken', refreshToken);

                cb();
            }
        }
        setIsLoading(false);
    }
    //Login

    async function handleLogin({ email, password }) {
        setIsLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");

        var raw = JSON.stringify({
            "email": email,
            "password": password
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };





        const isAuthUserRes = await (await fetch(`${baseUrl}/token`, requestOptions).catch(err => console.error(err))).json();

        if (isAuthUserRes['Access Token'] && typeof window !== "undefined") {

            token = isAuthUserRes['Access Token'];
            refreshToken = isAuthUserRes['Refresh Token'];
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);

            window.location.href = `/`;
            setErrorMsg(false);
        } else {
            setErrorMsg(isAuthUserRes.Message)

        }

        setIsLoading(false);

        return false;

    }


    // logout
    async function handleLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        setUser(false);
        window.location.href = '/';

    }


    return (
        <UserContext.Provider value={{ user,errorMsg, setUser, setRegisteredClasses, registeredClasses, isClassRegistered, isLoading, redirectUrl, setRedirectUrl, handleLogin, handleLogout, checkRegisteration }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;