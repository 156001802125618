/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { UserProvider } from './src/providers/user-provider';
import { ApiProvider } from './src/providers/api';

export const wrapRootElement = ({ element }) => (
    <UserProvider>
        <ApiProvider>
            {element}
        </ApiProvider>
    </UserProvider>
);