import React, { createContext, useState, useEffect } from "react";

const ApiContext = createContext();
const baseUrl = process.env.Base_URL;
export const ApiProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false);

    let token;
    let refreshToken;
    if (typeof window !== 'undefined') {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refreshToken');
    }

    //get classesdata
    // useEffect(() =>{
    //     getUserClassesData();
    // },[]);
    async function getRefreshToken(cb) {
        //fetch for new token / refresh token
        setIsLoading(true);

        if (typeof window !== 'undefined') {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + token);
            var raw = JSON.stringify({
                "AccessToken": token,
                "RefreshToken": refreshToken
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            let newToken = await (await fetch(`${baseUrl}/token/refresh`, requestOptions)).json();

            console.log(newToken);
            if (newToken['Access Token']) {
                token = newToken['Access Token'];
                localStorage.setItem('token', token);
                refreshToken = newToken['Refresh Token'];
                localStorage.setItem('refreshToken', refreshToken);

                cb();
            }
        }
        setIsLoading(false);
    }



    async function getClassInfo(id) {
        setIsLoading(true);
        var getInfoClassHeaders = new Headers();
        getInfoClassHeaders.append("Content-Type", "application/json");
        getInfoClassHeaders.append("Authorization", "Bearer " + token);

        var raw = "";

        var getClassInfos = {
            method: 'GET',
            headers: getInfoClassHeaders,
        };

        let ClassInfo;

        try {
            const resInfoClasses = await fetch(`${baseUrl}/classes/` + id, getClassInfos);

            if (resInfoClasses.status === 200) {
                ClassInfo = await resInfoClasses.json();
                console.log(resInfoClasses)
            } else if (resInfoClasses.status === 401 && resInfoClasses.statusText === 'Invalid token') {
                getRefreshToken(getClassInfo)
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
        return ClassInfo;

    }

    async function getEventsInfo() {
        setIsLoading(true);
        var getEventsHeaders = new Headers();
        getEventsHeaders.append("Content-Type", "application/json");
        getEventsHeaders.append("Authorization", "Bearer " + token);

        var raw = "";

        var getEvents = {
            method: 'GET',
            headers: getEventsHeaders,
        };

        let EventInfo;

        try {
            const events = await fetch(`${baseUrl}/classes`, getEvents);

            if (events.status === 200) {
                EventInfo = await events.json();
                console.log(events)
            } else if (events.status === 401 && events.statusText === 'Invalid token') {
                getRefreshToken(getEventsInfo)
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);

        return EventInfo;

    }

    async function getStreamLink() {
        setIsLoading(true);
        var getStreamLinkHeader = new Headers();
        getStreamLinkHeader.append("Content-Type", "application/json");
        getStreamLinkHeader.append("Authorization", "Bearer " + token);

        var raw = "";

        var getlink = {
            method: 'GET',
            headers: getStreamLinkHeader,
        };

        let streamLink;

        try {
            const link = await fetch(`${baseUrl}/videostreamApi`, getlink);

            if (link.status === 200) {
                streamLink = await link.json();
                console.log(link)
            } else if (link.status === 401 && link.statusText === 'Invalid token') {
                getRefreshToken(getStreamLink)
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);

        return streamLink;

    }

    async function getHistoryData() {
        setIsLoading(true);
        var getHistoryHeaders = new Headers();
        getHistoryHeaders.append("Content-Type", "application/json");
        getHistoryHeaders.append("Authorization", "Bearer " + token);

        var raw = "";

        var getUserHistory = {
            method: 'GET',
            headers: getHistoryHeaders,
        };

        let userHistory;

        try {
            const historyR = await fetch(`${baseUrl}/get_my_classes`, getUserHistory);

            if (historyR.status === 200) {
                userHistory = await historyR.json();

            } else if (historyR.status === 401 && historyR.statusText === 'Invalid token') {
                getRefreshToken(getHistoryData)
            }
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
        return userHistory;

    }

    async function getHomeData() {
        setIsLoading(true);
        var getHomeDataHeaders = new Headers();
        getHomeDataHeaders.append("Content-Type", "application/json");
        getHomeDataHeaders.append("Authorization", "Bearer " + token);

        var getHomeDataFetchProps = {
            method: 'GET',
            headers: getHomeDataHeaders,
        };

        let homeData;

        try {
            const resHomeData = await fetch(`${baseUrl}/homepageapi`, getHomeDataFetchProps);

            if (resHomeData.status === 200) {
                homeData = await resHomeData.json();
                console.log(resHomeData);

                setIsLoading(false);
            } else if (resHomeData.status === 401 && resHomeData.statusText === 'Invalid token') {
                getRefreshToken(getHomeData)
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
        setIsLoading(false);
        return homeData;
    }
    return (
        <ApiContext.Provider value={{ getClassInfo, getEventsInfo, getHistoryData, getHomeData, getStreamLink, isLoading }}>
            {children}
        </ApiContext.Provider>
    )
}

export default ApiContext;