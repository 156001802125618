// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-classes-js": () => import("./../../../src/pages/all-classes.js" /* webpackChunkName: "component---src-pages-all-classes-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-class-js": () => import("./../../../src/pages/class.js" /* webpackChunkName: "component---src-pages-class-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-education-breathworks-js": () => import("./../../../src/pages/education/breathworks.js" /* webpackChunkName: "component---src-pages-education-breathworks-js" */),
  "component---src-pages-education-how-to-live-a-pain-free-life-js": () => import("./../../../src/pages/education/how-to-live-a-pain-free-life.js" /* webpackChunkName: "component---src-pages-education-how-to-live-a-pain-free-life-js" */),
  "component---src-pages-education-index-js": () => import("./../../../src/pages/education/index.js" /* webpackChunkName: "component---src-pages-education-index-js" */),
  "component---src-pages-education-made-2-move-js": () => import("./../../../src/pages/education/made2move.js" /* webpackChunkName: "component---src-pages-education-made-2-move-js" */),
  "component---src-pages-education-the-5-amclub-js": () => import("./../../../src/pages/education/the5amclub.js" /* webpackChunkName: "component---src-pages-education-the-5-amclub-js" */),
  "component---src-pages-education-you-are-what-you-eat-js": () => import("./../../../src/pages/education/you-are-what-you-eat.js" /* webpackChunkName: "component---src-pages-education-you-are-what-you-eat-js" */),
  "component---src-pages-events-ggf-s-5-js": () => import("./../../../src/pages/events/ggf-s5.js" /* webpackChunkName: "component---src-pages-events-ggf-s-5-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-masyaf-fitness-fiesta-js": () => import("./../../../src/pages/events/masyaf-fitness-fiesta.js" /* webpackChunkName: "component---src-pages-events-masyaf-fitness-fiesta-js" */),
  "component---src-pages-events-spinning-marathon-js": () => import("./../../../src/pages/events/spinning-marathon.js" /* webpackChunkName: "component---src-pages-events-spinning-marathon-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-stream-js": () => import("./../../../src/pages/live-stream.js" /* webpackChunkName: "component---src-pages-live-stream-js" */),
  "component---src-pages-merchandise-js": () => import("./../../../src/pages/merchandise.js" /* webpackChunkName: "component---src-pages-merchandise-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-specialized-training-index-js": () => import("./../../../src/pages/specialized-training/index.js" /* webpackChunkName: "component---src-pages-specialized-training-index-js" */),
  "component---src-pages-specialized-training-kids-and-youth-js": () => import("./../../../src/pages/specialized-training/kids-and-youth.js" /* webpackChunkName: "component---src-pages-specialized-training-kids-and-youth-js" */),
  "component---src-pages-specialized-training-personal-training-js": () => import("./../../../src/pages/specialized-training/personal-training.js" /* webpackChunkName: "component---src-pages-specialized-training-personal-training-js" */),
  "component---src-pages-specialized-training-sports-performance-training-js": () => import("./../../../src/pages/specialized-training/sports-performance-training.js" /* webpackChunkName: "component---src-pages-specialized-training-sports-performance-training-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-team-ali-js": () => import("./../../../src/pages/team/ali.js" /* webpackChunkName: "component---src-pages-team-ali-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-karina-js": () => import("./../../../src/pages/team/karina.js" /* webpackChunkName: "component---src-pages-team-karina-js" */),
  "component---src-pages-team-khadiga-js": () => import("./../../../src/pages/team/khadiga.js" /* webpackChunkName: "component---src-pages-team-khadiga-js" */),
  "component---src-pages-team-lamiaa-js": () => import("./../../../src/pages/team/lamiaa.js" /* webpackChunkName: "component---src-pages-team-lamiaa-js" */),
  "component---src-pages-team-nirvana-js": () => import("./../../../src/pages/team/nirvana.js" /* webpackChunkName: "component---src-pages-team-nirvana-js" */),
  "component---src-pages-team-nour-js": () => import("./../../../src/pages/team/nour.js" /* webpackChunkName: "component---src-pages-team-nour-js" */),
  "component---src-pages-team-ramy-js": () => import("./../../../src/pages/team/ramy.js" /* webpackChunkName: "component---src-pages-team-ramy-js" */)
}

